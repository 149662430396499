<template>
  <div class="media recently-purchase" :class="{ show }" v-if="true" >
    <img src="/images/point_logo.png" class="media-height" alt="product" />
    <div class="media-body">
      <div>
        <div class="title d-block mb-2 product-name">
          {{ msgTitle }}
        </div>

        <p v-html="msgContent"></p>

      </div>
    </div>
    <a href="javascript:void(0)" @click="close()" class="close-popup">
      <Icon name="ri:close-fill"></Icon>
    </a>
  </div>
</template>

<script setup lang="ts">

const { $listen } = useNuxtApp()

let show = ref(false);

let msgTitle = ref("")
let msgContent = ref("")


function close() {
  show.value = false;
}

onMounted(() => {
  $listen("popup", ({title, content, timeout = 4000}) => {
    console.log("popup on event");
    
    msgTitle.value = title
    msgContent.value = content

    show.value = true

    setTimeout(() => {
      close()
    }, timeout);
  })  
});
</script>

<style lang="scss" scoped></style>
